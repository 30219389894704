<script setup>
import { computed, nextTick, ref, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useField, useForm } from 'vee-validate';
import { string } from 'yup';
import { DateTime } from 'luxon';
import { until } from '@vueuse/core';
import CSelect from '../base/CSelect.vue';
import { cmApi } from '@/api/instance';
import { useUserStore } from '@/stores/userStore';
import CSlideover from '@/components/base/CSlideover.vue';
import CButton from '@/components/base/CButton.vue';
import CCombobox from '@/components/base/CCombobox.vue';
import CInput from '@/components/base/CInput.vue';
import IconDrawerEmpty from '@/components/Icons/IconDrawerEmpty.vue';

const props = defineProps({
  modelValue: {
    // `modelValue` is only used for selecting an email. (i.e. when `isTemplateCreationMode` is `false`)
    type: [Object, null],
    required: false,
    default: null,
  },
  isSlideoverOpen: {
    default: false,
    type: Boolean,
    required: true,
  },
  isTemplateCreationMode: {
    // `isTemplateCreationMode` determines whether the slideover is for creating a new template or just selecting an email.
    // See comments on Step 1 and Step 2 below.
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['update:show', 'update:modelValue', 'selectTemplate']);

const {
  isSlideoverOpen,
  isTemplateCreationMode,
} = toRefs(props);

const baseURL = import.meta.env.VITE_API_BASE_URL;
const routeInfo = useRoute();
const orgId = computed(() => routeInfo.params.orgId);

const userStore = useUserStore();

const languagesMap = computed(() => {
  return isTemplateCreationMode.value ? userStore.currentUser?.languages_map : [];
});

const isMultiLanguageEnabled = computed(() => userStore.multiLanguageUser);
const isDynamicContentEnabled = computed(() => userStore.dynamicContentEnabled);

const wizardStepsCount = 2;
const wizardStep = ref(1);
const slideoverTitle = computed(() => {
  return isTemplateCreationMode.value ? 'Create a new email' : 'Recent Emails';
});

const createdByOptions = [
  {
    name: 'Team Templates',
    id: 'anyone',
  },
  {
    name: 'My Templates',
    id: 'me',
  },
  {
    name: 'Sample Templates',
    id: 'sample',
  },
];

const createdBy = ref(createdByOptions[0]);
function changeCreatedBy(name) {
  createdBy.value = name;
  loadEmails();
}

const isLoadingEmails = ref(true);
const isSampleTemplatesSelected = computed(() => createdBy.value.id === createdByOptions[2].id);
const emails = ref([]);
const searchQuery = ref('');
const selectedTemplate = ref(null);
const isMultiLanguageTemplate = ref(false);
const isDynamicContentTemplate = ref(false);

async function loadEmails() {
  isLoadingEmails.value = true;

  if (isSampleTemplatesSelected.value) {
    const { data } = await cmApi.get(`/teams/${orgId.value}/templates/sample_templates`);
    emails.value = data.sample_templates;
  }
  else {
    const params = {
      template_type: isTemplateCreationMode.value ? 'email_template' : 'email',
      query: searchQuery.value || null,
      tag_ids: searchTags.value.map(tag => tag.id),
      created_by: createdBy.value.id,
      update_thumbnails: true,
      sort_by: 'updated_at',
      order: 'DESC',
      page: 1,
      per_page: 24,
    };
    const { data } = await cmApi.get(`/teams/${orgId.value}/templates`, { params });

    emails.value = data.templates;
  }
  isLoadingEmails.value = false;
}

async function selectTemplate(email) {
  selectedTemplate.value = email;
  isMultiLanguageTemplate.value = selectedTemplate.value.multi_language;
  formIsMultiLanguage.value = isMultiLanguageTemplate.value;
  isDynamicContentTemplate.value = selectedTemplate.value.dynamic_content_enabled;
  formDynamicContentEnabled.value = isDynamicContentTemplate.value;

  if (isTemplateCreationMode.value) {
    await loadLanguages();
    showStep(2);
  }
  else {
    emits('update:modelValue', selectedTemplate.value);
    emits('selectTemplate');
  }
}

const fileRef = ref(null);
const selectedFile = ref(null);
async function selectFile(event) {
  selectedTemplate.value = null;
  selectedFile.value = event.target.files[0];
  isMultiLanguageTemplate.value = false;
  formIsMultiLanguage.value = false;
  resetDynamicContentFormValues();
  await loadLanguages();
  showStep(2);
}

async function startFromScratch() {
  selectedTemplate.value = null;
  selectedFile.value = null;
  isMultiLanguageTemplate.value = false;
  formIsMultiLanguage.value = false;
  resetDynamicContentFormValues();
  await loadLanguages();
  showStep(2);
}

function showStep(step) {
  wizardStep.value = step;
  nextTick(() => {
    scrollToTop();
  });
}

const tags = ref([]);
const searchTags = ref([]);
async function loadTags() {
  const { data } = await cmApi.get(`/teams/${orgId.value}/tags`);
  tags.value = data.tags.sort();
}
function removeItemById(collection, id) {
  collection.value = collection.value.filter(item => item.id !== id);
}
function removeSearchTagById(id) {
  removeItemById(searchTags, id);
  loadEmails();
}

const languages = ref([]);

async function loadLanguages() {
  if (!isMultiLanguageEnabled.value) {
    return;
  }

  await until(languagesMap).not.toBeUndefined(); // Waits until the user info response is loaded

  const endpoint = isMultiLanguageTemplate.value ? `/teams/${orgId.value}/templates/${selectedTemplate.value.id}/languages` : '/organization_languages';
  const { data } = await cmApi.get(endpoint);
  languages.value = data.languages.map((language) => {
    language.name = languagesMap.value[language.code];
    language.shortcut = isMultiLanguageTemplate.value ? language.title : `Read this email in ${language.name}`;
    language.symbol = language.code.split('-')[0];
    return language;
  });
  if (isMultiLanguageTemplate.value) {
    formLanguages.value = languages.value.slice(0, 4);
  }
}

const maxShortcutLength = 60;
function languageShortcutError(language) {
  const shortcutLength = language.shortcut.length;
  if (language.shortcut === language._previous_shortcut) {
    return language._shortcut_error;
  }

  let error = null;
  if (shortcutLength === 0) {
    error = 'Shortcut label is required';
  }
  else if (shortcutLength > maxShortcutLength) {
    error = 'Please limit the shortcut label to 60 characters';
  }

  language._shortcut_error = error;
  language._previous_shortcut = language.shortcut;

  return error;
}

const closeSlideover = () => {
  emits('update:show', false);
};

function resetFields() {
  wizardStep.value = 1;
  createdBy.value = createdByOptions[0];
  emails.value = [];
  selectedFile.value = null;
  searchTags.value = [];
  searchQuery.value = '';

  if (isTemplateCreationMode.value) {
    selectedTemplate.value = null;
    formTags.value = [];
    formLanguages.value = [];
    isMultiLanguageTemplate.value = false;
    formIsMultiLanguage.value = false;
    resetDynamicContentFormValues();
    resetForm();
  }
}

function previousPage() {
  selectedFile.value = null;

  if (isTemplateCreationMode.value) {
    selectedTemplate.value = null;
    formTags.value = [];
    formLanguages.value = [];
    isMultiLanguageTemplate.value = false;
    formIsMultiLanguage.value = false;
    resetDynamicContentFormValues();
    resetForm();
  }

  showStep(wizardStep.value - 1);
}

const { handleSubmit, isSubmitting, resetForm, meta: formMeta } = useForm();
const { value: formEmailTitle, errorMessage: nameError } = useField('title', string().required('Name is required'));
const { value: formIsMultiLanguage = false } = useField('is_multi_language');
const { value: formDynamicContentEnabled = false } = useField('isDynamicContent');

const resetDynamicContentFormValues = () => {
  isDynamicContentTemplate.value = false;
  formDynamicContentEnabled.value = false;
};

const formLanguages = ref([]);
function removeFormLanguageById(id) {
  removeItemById(formLanguages, id);
}

const isFormValid = computed(() => {
  if (isMultiLanguageEnabled.value && formIsMultiLanguage.value) {
    const isLanguagesValid = formLanguages.value.length > 0 && formLanguages.value.every(language => language._shortcut_error === null);
    return formMeta.value.valid && isLanguagesValid && !formDynamicContentEnabled.value;
  }
  else {
    return formMeta.value.valid;
  }
});

const onSubmitNewEmail = handleSubmit((values) => {
  const formData = new FormData();
  formData.append('template[name]', values.title);

  if (selectedTemplate.value && selectedTemplate.value.id) {
    formData.append('email_template_id', selectedTemplate.value.id);
  }
  else if (selectedFile.value) {
    formData.append('template[file]', selectedFile.value);
  }
  else if (isSampleTemplatesSelected.value) {
    formData.append('template[key]', selectedTemplate.value.name);
  }
  else {
    formData.append('template[key]', 'blank-template');
  }

  formTags.value.forEach((tag) => {
    formData.append('template[tag_ids][]', tag.id);
  });

  formData.append('template[dynamic_content_enabled]', formDynamicContentEnabled.value);

  if (isMultiLanguageEnabled.value && !isMultiLanguageTemplate.value) {
    orderedFormLanguages.value.forEach((language, index) => {
      formData.append(`template[languages_attributes][][organization_language_id]`, language.id);
      formData.append(`template[languages_attributes][][title]`, language.shortcut);
      formData.append(`template[languages_attributes][][position]`, index);
    });
  }

  cmApi.post(`/teams/${orgId.value}/templates`, formData).then(({ data }) => {
    window.location.href = `${baseURL}/teams/${orgId.value}/templates/${data.template.id}/edit`;
  });
});

const formTags = ref([]);
function removeFormTagById(id) {
  removeItemById(formTags, id);
}

function startDrag(event, dragLanguageId) {
  event.dataTransfer.setData('drag-language-id', dragLanguageId);
  event.dataTransfer.dropEffect = 'move';
  event.dataTransfer.effectAllowed = 'move';
  event.target.classList.add('opacity-50', 'pb-1', 'border-b-2', 'border-solid', 'border-black');
}
function endDrag(event) {
  event.target.classList.remove('opacity-50', 'pb-1', 'border-b-2', 'border-solid', 'border-black');
}

function onDrop(event, dropLanguageId) {
  event.preventDefault();
  const container = event.target.closest('#languages-order-container');

  const dropTarget = event.target.closest(`#language-${dropLanguageId}`);
  const dragLanguageId = event.dataTransfer.getData('drag-language-id');
  const dragTarget = container.querySelector(`#language-${dragLanguageId}`);

  const tmpElement = document.createElement('span');
  tmpElement.className = 'hidden';

  // Moves the dragged language to its new position
  const isDropTargetAfterDragTarget = dragTarget.compareDocumentPosition(dropTarget) === Node.DOCUMENT_POSITION_FOLLOWING;
  if (isDropTargetAfterDragTarget) {
    dropTarget.after(tmpElement);
  }
  else {
    dropTarget.before(tmpElement);
  }
  tmpElement.replaceWith(dragTarget);

  updateOrderedFormLanguages();
}

// Updates `orderedFormLanguages` to have the languages in the same order as shown in the languages container
const orderedFormLanguages = ref([]);
function updateOrderedFormLanguages() {
  const container = document.getElementById('languages-order-container');

  const ordersById = [];
  container.querySelectorAll('.language').forEach((element, index) => {
    const languageId = Number.parseInt(element.id.split('-')[1]);
    ordersById[index] = languageId;
  });

  orderedFormLanguages.value = formLanguages.value.slice().sort((a, b) => {
    return ordersById.indexOf(a.id) > ordersById.indexOf(b.id) ? 1 : -1;
  });
}

const maxLanguagesCount = 4;
watch(formLanguages, async (newValue, oldValue) => {
  if (newValue.length > maxLanguagesCount) {
    formLanguages.value.pop();
    return;
  }

  if (newValue.length === 0) {
    orderedFormLanguages.value = [];
    return;
  }

  const addedLanguages = newValue.filter(language => !oldValue.includes(language));
  if (addedLanguages.length > 0) {
    addedLanguages.forEach(language => orderedFormLanguages.value.push(language));
    return;
  }

  const anyRemovedLanguage = oldValue.find(language => !newValue.includes(language));
  if (anyRemovedLanguage) {
    updateOrderedFormLanguages();
  }
});

watch(isSlideoverOpen, (newValue) => {
  if (newValue) {
    resetFields();
    loadEmails();
    loadTags();
  }
});

function scrollToTop() {
  const element = document.getElementById('dialogTitle');
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}
</script>

<template>
  <CSlideover
    v-model:open="isSlideoverOpen"
    :title="slideoverTitle"
    @update:open="closeSlideover"
  >
    <!-- Step 1 -->
    <!-- NOTE: Here the user has to either select an email, upload one, or start from scratch. -->
    <!-- NOTE: Step 1 is the only shown step when `isTemplateCreationMode` is false. (e.g. for ODS) -->
    <div v-if="wizardStep === 1">
      <!-- Top bar search and create options -->
      <div class="h-full w-full inline-flex flex-col items-start justify-start gap-3 border-b border-slate-200 bg-white px-6 py-5">
        <div
          v-if="props.isTemplateCreationMode"
          class="w-full inline-flex self-stretch justify-between"
        >
          <div
            class="w-48"
          >
            <!-- Created By -->
            <CSelect
              v-model="createdBy"
              :options="createdByOptions"
              class="w-64"
              @update:model-value="changeCreatedBy"
            />
          </div>

          <div class="flex gap-1">
            <!-- Upload button -->
            <div class="group cursor-pointer">
              <input
                ref="fileRef"
                type="file"
                accept=".json"
                class="hidden"
                @change="selectFile"
              >
              <CButton
                v-tooltip="{
                  content: 'Import JSON',
                  placement: 'left',
                }"
                size="icon"
                button-type="icon"
                @click="fileRef.click()"
              >
                <div class="i-heroicons-solid:cloud-upload h-5 w-5" aria-hidden="true" />
              </CButton>
            </div>

            <CButton
              button-type="secondary"
              @click="startFromScratch"
            >
              Start from Scratch
            </CButton>
          </div>
        </div>

        <div class="relative w-full flex flex-row gap-1">
          <!-- Search by tags -->
          <div
            v-if="!isSampleTemplatesSelected"
            v-tooltip="{
              content: 'There are no tags',
              placement: 'bottom-start',
              disabled: tags.length > 0,
            }"
            class="w-1/2"
          >
            <CCombobox
              v-model="searchTags"
              :items="tags"
              label-property="name"
              key-property="id"
              :multiple="true"
              placeholder="Select tag(s)"
              :disabled="tags.length === 0"
              @update:model-value="loadEmails()"
              @remove="tag => removeSearchTagById(tag.id)"
            />
          </div>
          <!-- Search by name -->
          <div v-if="!isSampleTemplatesSelected" class="relative w-1/2 flex flex-row">
            <CInput
              v-model:input-value="searchQuery"
              placeholder="Search..."
              class="grow rounded-r-0"
              post-input
              @post-input-clicked="loadEmails()"
            >
              <template #postInput>
                <div class="i-heroicons:magnifying-glass-16-solid h-5 w-5 text-slate-400" />
              </template>
            </CInput>
          </div>
        </div>
      </div>

      <!-- Emails container -->
      <div
        class="h-full w-full bg-white px-6 pb-1 pt-6"
      >
        <!-- Emails skeleton -->
        <div v-if="isLoadingEmails" class="grid grid-cols-3 gap-x-3 gap-y-8">
          <div
            v-for="index in 6"
            :key="index"
            class="h-full inline-flex basis-1/3 flex-col items-start justify-start gap-4"
          >
            <!-- Email preview skeleton -->
            <div class="h-[167px] w-full animate-pulse rounded-lg bg-gray-300" />

            <!-- Email card skeleton -->
            <div v-if="!isSampleTemplatesSelected" class="h-[92px] w-full flex flex-col animate-pulse items-start justify-start gap-1.5">
              <!-- Title skeleton -->
              <div class="h-5 w-full rounded bg-gray-300" />

              <div class="w-full flex flex-col items-start justify-start gap-1.5">
                <!-- User skeleton -->
                <div class="w-full inline-flex items-center justify-start gap-1.5">
                  <i-heroicons:user-20-solid class="relative h-5 w-5 text-slate-400" aria-hidden="true" />
                  <div class="h-5 w-full rounded bg-gray-300" />
                </div>
                <!-- Date skeleton -->
                <div class="w-full inline-flex items-center justify-start gap-1.5">
                  <IconCalendarEdit class="h-5 w-5" />
                  <div class="h-5 w-full rounded bg-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loaded Emails -->
        <div v-if="!isLoadingEmails && emails.length > 0" class="grid grid-cols-3 gap-x-3 gap-y-8">
          <div
            v-for="email in emails"
            :key="isSampleTemplatesSelected ? email.name : email.id"
            class="h-full inline-flex basis-1/3 flex-col items-start justify-start gap-4"
          >
            <!-- Email selector -->
            <div
              class="group relative h-[167px] w-full flex flex-col cursor-pointer items-center justify-center overflow-hidden border border-slate-200 rounded-lg"
              @click="selectTemplate(email)"
            >
              <img
                class="h-full w-full rounded-md object-cover object-top transition duration-300 group-hover:scale-110 group-hover:blur-sm"
                :src="email.thumbnail_url"
              >
              <div class="absolute inset-0 w-full flex items-center justify-center">
                <CButton
                  size="md"
                  data-testid="selectTemplateButton"
                  class="opacity-0 group-hover:opacity-100"
                >
                  Select
                </CButton>
              </div>
              <CBadge
                v-if="email.multi_language && isMultiLanguageEnabled"
                class="absolute bottom-2 left-2"
                colour="purple"
                text="Multi-language"
                variant="rounded"
              />
            </div>

            <!-- Email card -->
            <div v-if="!isSampleTemplatesSelected" class="h-[92px] flex flex-col items-start justify-start gap-1.5">
              <div class="line-clamp-2 text-sm text-slate-800 font-bold leading-tight">
                {{ email.name }}
              </div>
              <div class="flex flex-col items-start justify-start gap-1.5">
                <div
                  v-tooltip="{
                    content: 'Created by',
                    placement: 'bottom-start',
                  }"
                  class="inline-flex items-center justify-start gap-1.5"
                >
                  <i-heroicons:user-20-solid
                    class="relative h-5 w-5 shrink-0 text-slate-400"
                    aria-hidden="true"
                  />
                  <div class="line-clamp-1 mt-1 text-xs text-slate-500 font-normal leading-none">
                    {{ email.user.firstname }} {{ email.user.lastname }}
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: 'Last edited on',
                    placement: 'bottom-start',
                  }"
                  class="inline-flex items-center justify-start gap-1.5"
                >
                  <IconCalendarEdit class="h-5 w-5 shrink-0" />
                  <div class="line-clamp-1 mt-1 text-xs text-slate-500 font-normal leading-none">
                    {{ DateTime.fromISO(email.updated_at).toFormat('EEE. MMM d, yyyy t') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Empty state -->
        <div
          v-if="!isLoadingEmails && emails.length === 0"
          class="h-full w-full inline-flex flex-col items-center justify-start gap-2"
        >
          <IconDrawerEmpty class="h-10 w-16" />
          <div class="h-[51px] flex flex-col items-center justify-start gap-[3px]">
            <div class="text-center text-lg text-slate-600 font-bold leading-loose">
              No {{ isTemplateCreationMode ? 'templates' : 'emails' }} found.
            </div>
            <div class="text-center text-xs text-slate-500 font-normal leading-none">
              Make sure that you've created {{ isTemplateCreationMode ? 'a template' : 'an email' }}, or try to make your search terms less specific.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2 -->
    <!-- NOTE: Here the user specifies email details such as name, tags, and languages, and creates the email. -->
    <!-- NOTE: The user only gets to step 2 when `isTemplateCreationMode` is true. -->
    <div
      v-if="wizardStep === 2"
    >
      <form
        id="newEmailForm"
        class="flex flex-col gap-6 bg-slate-50"
        @submit.prevent="onSubmitNewEmail"
      >
        <!-- Email general settings -->
        <div class="bg-white">
          <!-- Email name -->
          <div class="flex items-start self-stretch justify-between gap-4 border-b px-6 py-5">
            <label
              for="name"
              class="grow select-none pt-2 text-sm text-slate-700 font-semibold"
            >
              Name
            </label>
            <CInput
              v-model:input-value="formEmailTitle"
              :error-message="nameError"
              placeholder="Type here"
              class="basis-2/3"
            />
          </div>

          <!-- Email tags -->
          <div class="flex items-start self-stretch justify-between gap-4 border-b px-6 py-5">
            <label
              for="tags"
              class="grow select-none pt-2 text-sm text-slate-700 font-semibold"
            >
              Tags <span class="text-slate-500">(optional)</span>
            </label>
            <CCombobox
              v-model="formTags"
              :items="tags"
              label-property="name"
              key-property="id"
              :multiple="true"
              placeholder="Please select"
              class="w-2/3"
              @remove="tag => removeFormTagById(tag.id)"
            />
          </div>
        </div>

        <div class="flex flex-col">
          <!-- Dynamic content toggle -->
          <div
            v-if="isDynamicContentEnabled"
            class="h-fit bg-white"
          >
            <div class="flex items-center self-stretch justify-between gap-4 border-t-1 px-6 py-5">
              <div class="flex items-center gap-3">
                <div class="h-9 w-9 border border-slate-200 rounded-xl border-solid p-2">
                  <i-tabler:-user-bolt
                    class="h-5 w-5 text-primary-500"
                    aria-hidden="true"
                  />
                </div>
                <label
                  for="isDynamicContent"
                  class="grow select-none text-sm text-slate-700 font-semibold"
                >
                  Dynamic Content email
                </label>
              </div>
              <CToggle
                v-model="formDynamicContentEnabled"
                class="justify-items-end grid!"
                :disabled="formIsMultiLanguage || isDynamicContentTemplate"
              />
            </div>
          </div>

          <!-- Email language settings -->
          <div
            v-if="isMultiLanguageEnabled"
            class="h-fit bg-white"
          >
            <!-- Email multi-language toggle -->
            <div class="flex items-center self-stretch justify-between gap-4 border-y-1 px-6 py-5">
              <div class="flex items-center gap-3">
                <div class="h-9 w-9 border border-slate-200 rounded-xl border-solid p-2">
                  <i-heroicons:language
                    class="h-5 w-5 text-primary-500"
                    aria-hidden="true"
                  />
                </div>
                <label
                  for="is_multi_language"
                  class="grow select-none text-sm text-slate-700 font-semibold"
                >
                  Multi-Language email
                </label>
              </div>
              <CToggle
                v-model="formIsMultiLanguage"
                class="justify-items-end grid!"
                :disabled="formDynamicContentEnabled || isMultiLanguageTemplate"
              />
            </div>

            <transition
              enter-active-class="transition duration-200 ease-out"
              enter-from-class="transform opacity-0"
              enter-to-class="transform opacity-100"
              leave-active-class="transition duration-200 ease-in"
              leave-from-class="transform opacity-100"
              leave-to-class="transform opacity-0"
            >
              <div v-if="formIsMultiLanguage">
                <!-- Email languages -->
                <div class="flex items-start self-stretch justify-between gap-4 border-b px-6 py-5">
                  <div class="w-full flex basis-1/3 items-center self-stretch justify-between">
                    <label
                      for="languages"
                      class="grow select-none text-sm text-slate-700 font-semibold"
                    >
                      Email languages
                    </label>
                    <i-heroicons-solid:information-circle
                      v-tooltip="{
                        content: 'Select up to four languages. Admins can add and remove languages in the Settings.',
                        placement: 'bottom',
                      }"
                      class="h-5 w-5 text-slate-400 focus:outline-none"
                    />
                  </div>
                  <CCombobox
                    v-model="formLanguages"
                    :items="languages"
                    label-property="name"
                    key-property="symbol"
                    :multiple="true"
                    placeholder="Please select"
                    class="basis-2/3"
                    style="max-width: 65%"
                    :disabled="isMultiLanguageTemplate"
                    @remove="language => removeFormLanguageById(language.id)"
                  />
                </div>

                <!-- Email languages order -->
                <div
                  v-show="formLanguages.length > 0"
                  class="flex items-start self-stretch justify-between gap-4 border-b px-6 py-5"
                >
                  <div class="w-full flex basis-1/3 self-stretch justify-between py-2">
                    <label
                      for="languages-order"
                      class="grow select-none text-sm text-slate-700 font-semibold"
                    >
                      Languages order and shortcuts
                    </label>
                    <div>
                      <i-heroicons-solid:information-circle
                        v-tooltip="{
                          content: 'Use the handle on the right to reorder email languages. You can also customize the shortcuts buttons that will appear at the top of the email. <a href=\'https://help.contactmonkey.com/hc/en-us/articles/24128917289869-MULTI-LANGUAGE-EMAILS/\' target=\'_blank\' style=\'color: #30a3f9;\'><u>Read more in our Help Center.</u></a>',
                          placement: 'bottom',
                          html: true,
                          delay: { show: 500, hide: 2000 },
                        }"
                        class="h-5 w-5 text-slate-400 focus:outline-none"
                      />
                    </div>
                  </div>

                  <div
                    id="languages-order-container"
                    class="relative w-full flex basis-2/3 flex-col gap-2.5"
                  >
                    <div
                      v-for="(language) in formLanguages"
                      :id="`language-${language.id}`"
                      :key="language.id"
                      class="language flex flex-col gap-1"
                      :draggable="!isMultiLanguageTemplate"
                      @dragstart="startDrag($event, language.id)"
                      @dragend="endDrag($event)"
                      @drop="onDrop($event, language.id)"
                      @dragover.prevent
                      @dragenter.prevent
                    >
                      <!-- Language shortcut -->
                      <div class="w-full w-full flex items-center gap-2">
                        <div class="w-full flex">
                          <CInput
                            v-model:input-value="language.shortcut"
                            placeholder="Type here"
                            class="h-9 grow rounded-l-0"
                            :has-error="languageShortcutError(language)"
                            :disabled="isMultiLanguageTemplate"
                            pre-input
                          >
                            <template #preInput>
                              <div class="w-7 text-center text-sm font-normal uppercase">
                                {{ language.symbol }}
                              </div>
                            </template>
                          </CInput>
                        </div>

                        <i-charm-grab-vertical v-if="!isMultiLanguageTemplate" class="h-5 w-5 cursor-move text-gray-400" />
                      </div>

                      <!-- Language shortcut error -->
                      <div
                        v-show="languageShortcutError(language)"
                        class="h-4 text-xs text-red-700 font-normal leading-4"
                      >
                        {{ languageShortcutError(language) }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Languages order preview -->
                <div
                  v-show="formLanguages.length > 0"
                  class="flex flex-col cursor-default select-none gap-4 bg-neutral-100 px-6 py-4"
                >
                  <div class="text-sm text-slate-700 font-semibold">
                    Here's how the language shortcuts and the multi-language email will appear.
                  </div>

                  <div class="flex flex-col gap-1.5">
                    <div class="flex flex-wrap justify-center gap-1.5 border rounded-md bg-neutral-200 py-2">
                      <div
                        v-for="(language) in orderedFormLanguages"
                        :key="language.id"
                        class="border border-slate-300 rounded-md bg-white px-2 py-1 text-sm text-slate-700 font-semibold"
                      >
                        {{ language.shortcut.substring(0, maxShortcutLength) || '-' }}
                      </div>
                    </div>

                    <div
                      v-for="(language) in orderedFormLanguages"
                      :key="language.id"
                      class="language-preview border border-slate-300 rounded-md border-dashed py-5 text-center text-sm text-slate-500 font-semibold"
                    >
                      Your email in {{ language.name }}
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </form>
    </div>

    <template
      v-if="isTemplateCreationMode"
      #footer
    >
      <CButton
        v-show="wizardStep > 1"
        type="button"
        button-type="secondary"
        @click="previousPage()"
      >
        Previous
      </CButton>
      <div v-if="wizardStep === 1" />

      <div class="h-9 flex items-center gap-8">
        <div class="select-none text-sm text-slate-800 font-semibold">
          Step {{ wizardStep }} of {{ wizardStepsCount }}
        </div>

        <div class="flex items-center gap-5">
          <div
            v-for="index in wizardStepsCount"
            :key="index"
          >
            <div class="relative h-2.5 w-2.5">
              <div
                v-show="wizardStep === index"
                class="absolute left-[-5px] top-[-5px] h-5 w-5 rounded-full bg-primary-100"
              />
              <div
                class="h-2.5 w-2.5 rounded-full"
                :class="wizardStep >= index ? 'left-0 top-0 absolute bg-primary-400' : 'bg-slate-200'"
              />
            </div>
          </div>
        </div>
      </div>

      <CButton
        v-show="wizardStep === wizardStepsCount"
        v-tooltip="{
          content: 'All fields must have a valid value',
          disabled: isFormValid,
          autoBoundaryMaxSize: true,
        }"
        form="newEmailForm"
        type="submit"
        button-type="primary"
        :disabled="!isFormValid && !isSubmitting"
      >
        Create
      </CButton>
      <div v-if="wizardStep === 1" />
    </template>
  </CSlideover>
</template>

<style>
.v-popper--theme-tooltip .v-popper__inner {
  max-width: 475px;
}

.language-preview {
  background-image: repeating-linear-gradient(
    135deg,
    #E5EAF1 3px,
    transparent 5px,
    transparent 11px
  );
}
</style>
